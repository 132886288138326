import { AccountInfo } from "@azure/msal-common";
import {
  Experiment,
  FeatureFlag,
  Rollout,
} from "@optimizely/optimizely-sdk/lib/shared_types";
import { createInstance, ReactSDKClient } from "@optimizely/react-sdk";
import { config } from "config";
import cloneDeep from "lodash/cloneDeep";

export const cookieEnabledFeatureFlags = () =>
  document.cookie
    .split("; ")
    .filter((i) => i.startsWith("TesterEnabled_"))
    .reduce((acc: { [key: string]: boolean }, cur: string) => {
      const [key, value] = cur.split("=");
      if (key) {
        const name = key.split("TesterEnabled_").pop();
        if (name) {
          acc[name] = value.toLowerCase() === "true";
        }
      }
      return acc;
    }, {});

// add in comments explaining what's happening
export const matchCookieValuesToFF = (
  dataFile: any,
  enabledFeatures: { [key: string]: boolean },
): string[] => {
  // set empty array, to hold any matched Rollout IDs
  const rolloutIdsToFilter: string[] = [];

  // loop through all feature flag objects, to see if any keys match any set cookies
  dataFile.featureFlags.forEach((featureFlag: FeatureFlag) => {
    // loop through any feature flag tester cookies set
    Object.keys(enabledFeatures).forEach((key: string) => {
      // only check for cookies set to "false"
      if (enabledFeatures[key] === false) {
        // do some string replacements, to minimize any chances
        // that the audience attribute doesn't match the ff key
        const replacedKey = key.replace(/_|-/g, "").toLowerCase();
        const replacedFFValue = featureFlag.key
          .replace(/_|-/g, "")
          .toLowerCase();

        // if the cookie name matches the feature flag key, add to array
        if (replacedKey === replacedFFValue) {
          rolloutIdsToFilter.push(featureFlag.rolloutId);
        }
      }
    });
  });
  return rolloutIdsToFilter;
};

export const filterOutTraffic = (dataFile: any, enabledFeatures: {}): any => {
  const copiedDataFile = cloneDeep(dataFile);
  try {
    const rolloutIdsToFilter = matchCookieValuesToFF(dataFile, enabledFeatures);

    if (rolloutIdsToFilter) {
      // loop through all rollout objects
      copiedDataFile.rollouts.forEach((rollout: Rollout, index: number) => {
        // loop through array created above
        rolloutIdsToFilter.forEach((id: string) => {
          // if any matches, set traffic allocation to empty array
          // to make the cookie the definitive source of truth for feature flag enablement
          if (rollout.id === id) {
            copiedDataFile.rollouts[index].experiments.forEach(
              (experiment: Experiment) => {
                if (!experiment.audienceConditions) {
                  experiment.trafficAllocation = [];
                }
              },
            );
          }
        });
      });
      return copiedDataFile;
    }
  } catch {
    return copiedDataFile;
  }
};

export const getOptimizelyClient = (
  accountInfo: AccountInfo,
): ReactSDKClient => {
  const globalAny = global as any;
  const datafile = globalAny.window.optimizelyDatafile;

  // get enabledFeatures obj, based on cookies;
  const enabledFeatures = cookieEnabledFeatureFlags();
  // create the optimizely client, and assign custom logger and log level
  const client = createInstance({
    sdkKey: config.optimizelyKey,
    // get filtered out traffic from Optimizely, depending on cookie status
    datafile: filterOutTraffic(datafile, enabledFeatures),
    datafileOptions: {
      autoUpdate: true,
      urlTemplate: `${config.optimizelyDomain}/datafiles/%s.json`,
    },
  });

  // set client user info
  client.setUser({
    id: accountInfo.homeAccountId,
    attributes: enabledFeatures,
  });
  return client;
};
