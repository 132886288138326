// all feature flags are in all lowercase, they can be entered with capitals for the "name", optimizely will lowercase the whole flag for the key
export const phase3AnnouncementDetail = "34466-freeway-announcement-detail";
export const phase3AnnouancementsEnabled = "37858-freeway-phase3-listpage";
export const iicoEnabled = "34772-iico-ff";
export const afpEnabled = "48977-autofinderplus-main-ff";
export const vvtKeysQuestionUpdateEnabled = "58295-vvt-keys-question-update";
export const vvtConditionDescriptionsUpdated =
  "58107-vvt-add-condition-descriptions";
export const vvtConditionRustHailDamageQuestionEnabled =
  "58720-vvt-vehicle-conditions-rust-hail-damage-question";
