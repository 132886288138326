const Copy = {
  common: {
    viewAllAnnouncements: "View All Announcements",
    yes: "Yes",
    no: "No",
    next: "Next",
    requiredField: "This field is required.",
    genericErrorTitle: "Error",
    genericErrorMessage: "An error has occured.",
  },
  crmSection: {
    heading: "Freeway CRM",
    description: "View and manage all leads and customers.",
    linkText: "Launch CRM",
  },
  navigation: {
    home: "Home",
    announcements: "Announcements",
    iico: "Vehicle Valuation",
    afp: "Auto Finder Plus",
  },
  vehicleValuation: {
    title: "Driveway Vehicle Valuation",
    description:
      "Offers are updated daily & match valuations from Driveway. " +
      "They are based on license plate or VIN, location, trim, features, & vehicle condition.",
    forms: {
      vehicleLookup: {
        title: "Identify Vehicle",
        licensePlateGroupLabel: "License Plate",
        licensePlateLabel: "Plate Number",
        licensePlatePlaceholder: "Ex: ABC123",
        stateLabel: "Registered State",
        statePlaceholder: "Select State",
        vinLabel: "VIN",
        vinHelperText:
          "Must be exactly 17 characters in length and never include the letters “I”, “O”, and “Q.”",
        vinPlaceholder: "Ex: 1GCCW80H7CR161832",
        errors: {
          vin: "Must be exactly 17 characters in length and never include the letters “I”, “O”, and “Q.”",
          licenseplate: "Please enter a valid plate number.",
        },
        notFound: {
          vin: "We couldn’t find the car with the information entered. Please double-check and try again or you can use the License Plate.",
          licenseplate:
            "We couldn’t find the car with the information entered. Please double-check and try again or you can use the VIN.",
        },
      },
      trimSelection: {
        title: "Select Trim",
        multiTrimDescription1: "Select the vehicle trim from the list below.",
        singleTrimDescription1: "Our records indicate that this vehicle is a ",
        singleTrimDescription2: " with the ",
        singleTrimDescription3: " Trim.",
        trimLabel: "Trim",
        trimPlaceholder: "Select vehicle trim",
        error: "Please select a trim.",
      },
      details: {
        title: "Add Details",
        mileageInputLabel: "Mileage",
        mileageInputPlaceholder: "Current Odometer Reading",
        errorMileageTooLong: "Mileage cannot be over 6 digits.",
        errorMileageNotEntered: "Enter the mileage.",
        errorMileageNotValidValue: "Invalid value for mileage.",
        zipInputLabel: "Zip Code",
        zipInputPlaceholder: "5 digit Zip Code",
        zipInputError: "Please enter a 5-digit postal code.",
        optionsTitle: "Options",
        optionsHelpText:
          "Focus on details that impact vehicle value. (Optional)",
        noZoneWarning: "The zip code you entered is not in a Driveway zone.",
      },
      condition: {
        title: "Verify Condition",
        drivebleQuestion: "Can this vehicle be driven safely?",
        overallCondition: {
          question: "What is the condition of the vehicle?",
          great: "Great",
          good: "Good",
          fair: "Fair",
          poor: "Poor",
        },
        warningLightsConditionQuestion: "Are there active warning lights?",
        rustHailDamageQuestion: "Is there any rust or hail damage?",
        aftermarketQuestion: "Are there any aftermarket modifications?",
        accidentQuestion: "Is the vehicle currently damaged from an accident?",
        smokingQuestion: "Has the vehicle been smoked in?",
        keysQuestion: "How many keys are available?",
        loanLeaseQuestion: "Any active loan or lease on the vehicle?",
      },
    },
    summary: {
      sections: {
        default: {
          title: "Vehicle Identification",
          description: "Enter vehicle information to begin.",
        },
        identification: {
          title: "Vehicle Identification",
          vin: "VIN",
        },
        details: {
          title: "Details",
          mileage: "Mileage",
          postalCode: "Zip Code",
          options: "Options",
          isInMarket: "Vehicle In Market?",
          noOptionsSelected: "None",
          noOptionsAvailable: "None",
        },
        condition: {
          title: "Condition",
          drivable: "Drivable",
          condition: "Condition",
          warningLights: "Warning Lights",
          aftermarket: "Aftermarket Modifications",
          accident: "Accident?",
          smokeIn: "Smoked in?",
          keys: "Keys",
          loanLease: "Loan or Lease",
        },
      },
    },
    offer: {
      title: "Driveway Offer Details",
      noOfferTitle: "No Offer Available",
      errorTitle: "There was an error getting an offer.",
      copyButton: "Copy Offer Details",
      generateOfferButtonText: "Generate Offer",
      createdOn: "Created on:",
      generatedBy: "Generated by:",
      expiresIn: "Offer expires in 7 days",
      disclaimer:
        "*Offer pending validation of vehicle condition, ownership, and any lien payoff information.",
      noOfferDisclaimer1:
        "*We do not have enough data available to place a value on this vehicle. ",
      noOfferDisclaimer2:
        "If you are a DCC Agent, please contact HQ Appraisal.",
      noOfferTooltip:
        "No offers result from vehicles that meet one or more criteria: too old, too new, too deluxe, existing recalls, high mileage per year, region outside of ICO range, or lack of comparable vehicles.",
    },
  },
  offerCalculationModal: {
    tooltipButtonText: "How we calculate offers",
    tooltipNoOfferButtonText: "What is a no offer result?",
    header: "How We Calculate Offers",
    body1:
      "We want to give customers the most accurate and fair offer for their vehicle. We do so by evaluating the vehicle information and history, and compare it to similar vehicles in the area.",
    body2:
      "We partner with the most reliable names in the business and use a sophisticated algorithm to generate a value that ensures we’re making our best offer up front.",
    body3:
      "Our algorithm evaluates vehicles based on geo-targeted wholesale and retail data, as well as specific vehicle information for that VIN.",
    body4:
      "Customers outside of our Driveway markets (OOM) will see slightly lower offer amounts, due to a lack of valets that can pick up the vehicle. " +
      "We work with third-party transport carriers to pick up these vehicles, which results in a higher cost for us to buy those vehicles. ",
  },
  infoModal: {
    drivability: {
      title: "DRIVABILITY GUIDELINES",
      body: "The vehicle must be capable of running and driving over 50 miles, with no major issues, and have functioning brakes, steering, and safety devices.",
      tooltipText: "Drivability Guidelines",
    },
    aftermarket: {
      title: "AFTERMARKET MODIFICATIONS",
      body: "These includes non-original parts like lift kits, exhaust mods, custom lights, wheels/tires, or engine/dashboard changes.",
      tooltipText: "Aftermarket Modification Guidelines",
    },
    rustHailDamage: {
      title: "RUST OR HAIL DAMAGE GUIDELINES",
      body: "Rust or hail damage to a vehicle includes broken glass, dents, paint chips or major rust in the spare tire wells, exhaust or suspension.",
      tooltipText: "Rust or Hail Damage guidelines",
    },
  },
  conditionHelperModal: {
    title: "CAR CONDITION GUIDELINES",
    tooltipButtonText: "Vehicle Condition Guidelines",
    v1: {
      header: {
        GREAT: "Great (5% of cars):",
        GOOD: "Good (25% of cars):",
        FAIR: "Fair (50% of cars):",
        POOR: "Poor (20% of cars):",
      },
      subheader: {
        GREAT: "Looks like it’s never been driven.",
        GOOD: "Customer has had the car for a while but it has minimal signs of wear or visible defects.",
        FAIR: "The car has clearly been driven for some time.",
        POOR: "This car has seen better days, repair work needed.",
      },
      body: {
        GREAT:
          "Smells like a new car, has never had any bodywork. The engine compartment, wheels, and tires are like new. Overall the car needs no repairs.",
        GOOD: "Only minor paint or bodywork has been done. Wheels are flawless and tires have 75% or more of tread remaining. Requires minimal repairs.",
        FAIR:
          "There might be some mechanical repairs, bodywork, or paint needed. The interior may have substantial wear or even small tears. " +
          "Wheels and/or tires could require replacement. The car needs servicing, but is still in reasonable running condition.",
        POOR:
          "Requires some mechanical repair, paint, bodywork, and/or engine compartment repair. The car may have rust damage, and the interior might have substantial wear. Wheels and/or tires could require replacement. " +
          "The car needs servicing but is still in reasonable running condition.",
      },
    },
    v2: {
      header: {
        GREAT: "Great",
        GOOD: "Good",
        FAIR: "Fair",
        POOR: "Poor",
      },
      body: {
        GREAT:
          "The vehicle is in excellent condition and looks almost good as new! It has less than three minor defects that can be easily fixed, and the tires may be new or have very little wear.",
        GOOD: "No significant damage is present, and the vehicle is in above-average condition. The tires aren’t new, but they have a lot of miles left on them. Only minor body damages, small chips, and small to medium dents may need repairing.",
        FAIR: "The customer has owned the vehicle a while and it has signs of normal wear and tear. Some parts may need to be repaired and the tires likely need replacing soon. No major repairs, like structural damage, are required.",
        POOR: "The vehicle has seen better days and is showing signs of excessive wear and tear. Parts may be broken or missing, the tires need to be replaced, and some damages may not be fully repairable.",
      },
    },
  },
  financeModal: {
    header: "ACTIVE LOAN OR LEASE",
    body1:
      "Getting this information from a customer will give an idea of the vehicle’s equity. A customer can still sell or trade in their vehicle even if they still owe money.",
    tooltipButtonText: "Why does Driveway need this?",
  },
  autoFinderPlus: {
    title: "Auto Finder Plus",
  },
  toasts: {
    offerGenerated: {
      title: "Offer Generated",
      message: "An offer has been calculated.",
    },
    offerUpdated: {
      title: "Offer Updated",
      message: "An offer has been calculated.",
    },
    noOffer: {
      title: "No Offer",
      message: "No offer is available at this time.",
    },
    offerCopied: {
      title: "Offer Copied",
      message: "The offer has been copied to your clipboard.",
    },
    timeout: {
      title: "Timeout Error",
      message:
        "We’re unable to process your request with the zip code entered. Please reload and try again in a few minutes. If you still are unable to submit the form, contact Service Now.",
      actionButtonText: "Contact ServiceNow",
    },
    apiError: {
      title: "Connection Issue",
      message:
        "Something went wrong on our end. Please refresh and try again. If the problem continues, create a ServiceNow Ticket.",
      actionButtonText: "Contact ServiceNow",
    },
  },
};

export default Copy;
